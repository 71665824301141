import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import bottomBackground from '../../assets/backgrounds/biscuit-2-bottom.svg';
import featureNoMask from '../../assets/backgrounds/feature-no mask.svg';
import { Visibility } from '../../types';

const TOP_BACKGROUND_HEIGHT = '1.32vw';

export const SafariWrapper = styled.div`
  width: 100%;
  margin-bottom: -${TOP_BACKGROUND_HEIGHT};
  padding-bottom: ${TOP_BACKGROUND_HEIGHT};
  overflow: hidden;


  ${({ showWowIcon }) =>
    showWowIcon &&
    `
      &:before {
        content: '';
        width: 300px;
        height: 300px;
        background: url('/salespage/5th section/Wow-unicorn.svg') no-repeat center 0;
        background-size: contain;
        display: block;
        position: absolute;
        z-index: 100;
        margin-left: 70%;
        margin-top: -100px;
        
        @media screen and (max-width: 1300px) {
          width: 258px;
          height: 280px;
          margin-top: -80px;
          margin-left: 77%;
        }
        @media screen and (max-width: 1100px) {
          display: none;
        }
        // @media screen and (max-width: ${breakpoints.md}px) {
        //   transform: scale(0.7);
        // }
        
        // @media screen and (max-width: ${breakpoints.sm}px) {
        //   display: none;
        // }
      }

    `}
`;
    

export const Wrapper = styled.section<Visibility>`
  position: relative;
  padding-top: 88px;
  padding-bottom: 18px;
  background-color: ${colors.biscuit};
  
  
  /*opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  ${({ isVisible }) =>
  isVisible &&
  `
    opacity: 1;
    transform: translateY(0);
`}*/


  @media screen and (max-width: ${breakpoints.lg}px) {
    padding-top: 40px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    padding-top: 20px;
  }





  &:after {
    content: '';
    width: 100%;
    height: ${TOP_BACKGROUND_HEIGHT};
    bottom: -${TOP_BACKGROUND_HEIGHT};
    position: absolute;
    left: 0;
    background: url(${bottomBackground}) no-repeat center 0;
    background-size: contain;
    transform: scale(1.02);

    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.05);
    }
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    display: none;
  }
`;

export const Heading = styled.header`
  display: flex;
  max-width: 920px;
  margin: 0 auto;
  padding: 0 ${dimensions.containerPadding}px;
  flex-direction: column-reverse;
  text-align: center;

`;

export const Title = styled.h2`
  margin: 0;
  font-size: ${dimensions.fontSize.heading}px;
  line-height: ${dimensions.lineHeight.heading};
  font-weight: 900;

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:24px;
  }
`;

export const SubTitle = styled.h3`
  margin: 0 0 18px 0;
  color: #9d978b;
  font-weight: 900;
  font-size: ${dimensions.fontSize.large}px;
  line-height: ${dimensions.lineHeight.large};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.large * 0.8}px;
  }
`;

export const Note = styled.h5` 
  font-size: ${dimensions.fontSize.medium}px;
  line-height: ${dimensions.lineHeight.regular};
  background: url('/carousel/note-background2.svg') no-repeat center 0;
  background-size: contain;
  width: 870px;
  height: 70px;
  margin:auto;
  margin-top: 40px;
  padding-top: 12px;
  font-weight: 400;

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.medium * 0.8}px;
    width: 700px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    background: none;
    width: 100%;
    /*background-size: 100% 40px;
    background-position: 0px   10px;*/
  }
`;

export const Carousel = styled.div`
  margin-top: 114px;


  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: -120px;
    transform: scale(0.5);
    transform-origin: bottom;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-top: -200px;
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  display: flex;
  transition: transform 0.3s ease-in-out;
`;

interface CarouselItem {
  isActive?: boolean;
}

export const CarouselItem = styled.figure<CarouselItem>`
  display: flex;
  width: 503px;
  height: 503px;


  margin: 0 -74px;
  padding: 0;
  position: relative;
  background: url(${featureNoMask}) no-repeat center 0;
  background-size: contain;
  ${({ isActive }) =>
    isActive &&
    `
      position: relative;
      z-index: 2;
      transform: scale(1.5);
      cursor: pointer;

      @media screen and (max-width: ${breakpoints.lg}px) {
        transform: scale(2.1);
      }

      @media screen and (max-width: ${breakpoints.md}px) {
        transform: scale(2);
      }
      @media screen and (max-width: ${breakpoints.sm}px) {
        transform: scale(1.3);


      }

  `}
  ${({ sketch }) =>
  sketch == "customer" &&
  `
  
    cursor: default;

  `}
  transition: transform 0.3s ease-in-out;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: center;

  img {
    width: 58%;
    top: 57px !important;
    ${({ sketch }) =>
    sketch == "customer" &&
    `
    
      top: 65px !important;
  
    `}
    position: absolute;
  }

  img.rs{
    width: 46%;
  }
  img.rs1{
    width: 41%;
  }
`;

export const CarouselItemTitle = styled.figcaption`
  margin-top: 0;
  padding: 0;
  position: absolute;
  bottom: 132px;
  font-size: 9px;
  font-weight: 900;
`;

export const CarouselItemDesc = styled.figcaption`
  margin-top: 0;
  padding: 0;
  position: absolute;
  bottom: 40px;
  font-size: 9px;
  font-weight: 400;
  width: 300px;
`;

export const CarouselNav = styled.nav`
  display: flex;
  margin-top: -30px;
  margin-left: -18px;
  position: relative;
  z-index: 3;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.xxl}px) {
    margin-left: -12px;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 50px;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 4px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-top:0;
  }


`;

interface CarouselNavDotProps {
  isActive: boolean;
}

export const CarouselNavDot = styled.button<CarouselNavDotProps>`
  width: 8px;
  height: 8px;
  margin: 0 6px;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 0;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
      margin: 0 10px;
      transform: scale(2);

      path {
        fill: ${colors.navyBlue};
      }
  `}

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover,
  &:focus {
    outline: 0;

    path {
      fill: ${colors.navyBlue};
    }
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    ${({ sketch }) =>
    sketch == "customer" && 
    `
    
    opacity: 0;
  
    `}
  }

  @media screen and (max-width: 400px) {
    display: none;
  }
`;

export const CarouselNavButtonLeft = styled.button`
  margin: 16px 42px 0 42px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.8;
    outline: 0;
  }

  svg {
    width: 58px;
    height: 44px;
  }


  @media screen and (max-width: ${breakpoints.sm}px) {
    ${({ sketch }) =>
    sketch == "customer" && 
    `
    
      margin-left: 40px;
  
    `}
  }

  @media screen and (max-width: 400px) {
    ${({ sketch }) =>
    sketch == "customer" && 
    `
    
      margin-left: 20px;
  
    `}
  }


`;
export const CarouselNavButtonRight = styled.button`
  margin: 16px 42px 0 42px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.8;
    outline: 0;
  }

  svg {
    width: 58px;
    height: 44px;
  }


  @media screen and (max-width: ${breakpoints.sm}px) {
    ${({ sketch }) =>
    sketch == "customer" && 
    `
    
    margin-left: -200px;
  
    `}
  }

  @media screen and (max-width: 400px) {
    ${({ sketch }) =>
    sketch == "customer" && 
    `
    
      margin-left: 20px;
  
    `}
  }



`;